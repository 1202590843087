import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'formatTimestamp'
})
export class FormatTimestamp implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const sec_num = parseFloat(value);
      const hours   = Math.round(Math.floor(sec_num / 3600) * 100) / 100;
      const minutes =  Math.round(Math.floor((sec_num - (hours * 3600)) / 60) * 100) / 100;
      const seconds = Math.round((sec_num - (hours * 3600) - (minutes * 60)) * 100) / 100;

      let fHrs = hours.toString();
      let fMin = minutes.toString();
      let fSec = seconds.toString();

      if (hours   < 10) { fHrs   = '0' + hours; }
      if (minutes < 10) { fMin = '0' + minutes; }
      if (seconds < 10) { fSec = '0' + seconds; }

      return fHrs + ':' + fMin + ':' + fSec;
    } else {
      return value;
    }
  }
}
