import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { Ng2Webstorage } from 'ngx-webstorage';
import { Select2Module } from 'ng2-select2';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { TextMaskModule } from 'angular2-text-mask';
import { QuillEditorModule } from 'ngx-quill-editor';
import { NgDragDropModule } from 'ng-drag-drop';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ContextMenuModule } from 'ngx-contextmenu';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { SpinnerModule, TableModule, AutocompleteModule } from './modules'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

import {
  ModalPopupComponent,
  IconComponent,
  LoaderComponent,
  SpinnerComponent,
  ConfirmationDialogComponent,
  AudioControllerComponent,
  ValidationErrorsComponent
} from './components';

import {
  CurrencyFormat,
  RemoveUnderscorePipe,
  CapitalizeFirstPipe,
  FormatTimestamp,
  FormatPhoneNumberPipe,
  StatusFormats,
  OrderByPipe,
  AbsoluteValue
} from './pipes';
import { AsideToggleDirective, ClickOutsideDirective } from './directives';
import { ToasterService, ConfirmationDialogService, StorageService, UtilityService } from './services';
import { AuthInterceptor } from './interceptor';
import { AccessChecker, IsGrantedDirective } from './security';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    HttpClientModule,
    TranslateModule.forChild({}),
    Ng2Webstorage.forRoot({ prefix: 'salesonepro', separator: '|'}),
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NgbModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    Select2Module,
    PerfectScrollbarModule,
    ChartsModule,
    QuillEditorModule,
    SpinnerModule,
    TableModule,
    AutocompleteModule,
    NgDragDropModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    InfiniteScrollModule
  ],

  declarations: [
    SpinnerComponent,
    LoaderComponent,
    IconComponent,
    ModalPopupComponent,
    ConfirmationDialogComponent,
    IsGrantedDirective,
    AsideToggleDirective,
    ClickOutsideDirective,
    CurrencyFormat,
    FormatTimestamp,
    RemoveUnderscorePipe,
    FormatPhoneNumberPipe,
    AudioControllerComponent,
    ValidationErrorsComponent,
    CapitalizeFirstPipe,
    StatusFormats,
    OrderByPipe,
    AbsoluteValue
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,
    SpinnerComponent,
    LoaderComponent,
    IconComponent,
    ModalPopupComponent,
    IsGrantedDirective,
    AsideToggleDirective,
    ClickOutsideDirective,
    PerfectScrollbarModule,
    NgbModule,
    OwlDateTimeModule,
    Select2Module,
    ChartsModule,
    CurrencyFormat,
    FormatTimestamp,
    RemoveUnderscorePipe,
    FormatPhoneNumberPipe,
    CapitalizeFirstPipe,
    TextMaskModule,
    QuillEditorModule,
    AudioControllerComponent,
    ValidationErrorsComponent,
    ContextMenuModule,
    SpinnerModule,
    TableModule,
    AutocompleteModule,
    StatusFormats,
    NgDragDropModule,
    CalendarModule,
    OrderByPipe,
    AbsoluteValue,
    InfiniteScrollModule
  ],
  providers: [
    ToasterService,
    ConfirmationDialogService,
    StorageService,
    UtilityService,
    AccessChecker,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MY_NATIVE_FORMATS
    }
  ],
  entryComponents: [ ConfirmationDialogComponent ]
})
export class SharedModule {}
