import {Injectable} from '@angular/core';
import { formatDate } from '@angular/common';

@Injectable()

export class UtilityService {

  /**
   * Used to format date
   * @param currentDate
   */
  formatDate(currentDate, format = 'yyyy-MM-dd') {
    if (currentDate) {
      const date = new Date(currentDate);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }
    return currentDate;
  }

  /**
   * Used to format date time
   * @param currentDate
   */
  formatDateTime(currentDateTime, format = 'yyyy-MM-dd HH:mm:ss') {
    if (currentDateTime) {
      const date = new Date(currentDateTime);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }

    return currentDateTime;
  }


  /**
   * Used to format  time
   * @param currentDate
   */
  formatTime(currentDateTime, format = 'HH:mm:ss') {
    if (currentDateTime) {
      const date = new Date(currentDateTime);
      const modifiedDate = formatDate(date, format, 'en');

      return modifiedDate;
    }

    return currentDateTime;
  }

  getDateObject(dateObj, hourString) {
    const arr = hourString.split(':')
    const d = new Date(dateObj);
    d.setHours(arr[0], arr[1], arr[2], 0)
    return d;
  }
}
