import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StorageService } from '../services/storage.service';
/**
 * Access checker service.
 *
 * Injects `RoleProvider` to determine current user access permissions
 */
@Injectable()
export class AccessChecker {

  constructor(
    private storage: StorageService,
  ) {}

  /**
   * Checks whether access is granted or not
   *
   * @param {string} permission
   * @returns {Observable<boolean>}
   */
  isGranted(role: string): Observable<boolean> {
    if (!role) {
      throw new Error('Role name cannot be empty');
    }
    if (this.storage.retrieve('acl', 'session')) {
      const permission = this.storage.retrieve('acl', 'session');
      return permission.includes(role);
    }

    return Observable.of(false);
  }
}
