import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { User, AuthService, StorageService, SocketService, ToasterService } from '@shared/index';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: User;
  mainNavigation: any = [];
  userEmail: string;
  userAcronym: string;

  loadingMainNav: boolean;

  start: Number = 1;
  params: HttpParams;
  loadingNotifications: boolean;
  notifications = {
    count: 0,
    results: [],
    unviewed_count: 0,
    next: '',
    previous: '',
  };
  notificationList: Array<any> = [];

  updateNotification: boolean;
  notificationCount: 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageService,
    private socketService: SocketService,
    private toastr: ToasterService
  ) {
    // this.socketService.messages.subscribe(msg => {
    //   this.toastr.info(msg['message'], 'Notification', {
    //     disableTimeOut: true
    //   });
    //   this.getNotifications();
    // });

    this.params = new HttpParams();
    this.params = this.params.delete('limit');
    this.params = this.params.append('limit', environment.tableRowLimit);
    this.params = this.params.delete('read');
    this.params = this.params.append('read', 'false');
  }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        this.userEmail = userData['email'];
        const matches = userData['name'].match(/\b(\w)/g);
        this.userAcronym = matches.join('');
      }
    )

    this.loadingMainNav = true;
    if (this.storage.retrieve('navigation', 'session')) {
      this.loadingMainNav = false;
      this.mainNavigation = this.storage.retrieve('navigation', 'session');
    } else {
      this.storage.observe('leftNavigation', 'session')
      .subscribe((value) => {
        setTimeout(() => {
          this.loadingMainNav = false;
          this.mainNavigation = this.storage.retrieve('navigation', 'session');
        });
      }, error => {
        this.loadingMainNav = false;
      });
    }

    this.notificationList = [];
    // this.getNotifications();
  }

  logout() {
    this.authService.purgeAuth()
    this.router.navigateByUrl('/login').then(() => {
      this.router.navigate(['/', 'login']);
    });
  }

  onRouteClick(item) {
    let redirectRoute = item['slug'];
    if (item['children']['left'] && item['children']['left'][0]) {
      const leftMainRoute = item['children']['left'][0];
      redirectRoute += '/' + leftMainRoute['slug'];
      if (leftMainRoute['children'] && leftMainRoute['children']['left'] && leftMainRoute['children']['left'][0]) {
        redirectRoute += '/' + leftMainRoute['children']['left'][0]['slug'];
      }
    }
    this.router.navigateByUrl(redirectRoute);
  }


  routeToDefaultPath() {
    if (this.storage.retrieve('defaultRoute', 'session')) {
      const defaultRoute = this.storage.retrieve('defaultRoute', 'session');
      this.router.navigate([defaultRoute]);
    } else {
      this.router.navigate(['/', 'dashboard']);
    }
  }

  getNotifications() {
    this.loadingNotifications = true;
    this.authService.getNotifications(this.params).subscribe(response => {
      this.notifications = response;
      response.results.forEach(element => {
        let message = element.message;
        let link = false;
        if (element.type === 'order_status_due_date_update') {
          message = element['message'];
          link = true;
        }
        const tmp = {
          message: message,
          data: element['data'][element.type],
          type: element.type,
          link: link
        }
        this.notificationList.push(tmp)
      });
      this.loadingNotifications = false;
      this.notificationCount = response.unviewed_count;
    }, error => {
      this.loadingNotifications = false;
    });
  }

  onScrollDown() {
    if (this.notifications.next && this.notifications.next !== '') {
      const tmp = this.notifications.next.split('?');
      if (tmp[1]) {
        const params = tmp[1].split('&');
        params.forEach(element => {
          const data = element.split('=');
          this.params = this.params.delete(data[0]);
          this.params = this.params.append(data[0], data[1]);
        });
        this.getNotifications();
      }
    }
  }

  viewDetails(item) {
    if (item.link) {
      const id = item.data.order.order_number;
      if (item.data.order.order_status__type === 'quote') {
        this.router.navigate(['./sales/quotes/details/', id]);
      } else {
        this.router.navigate(['./sales/orders/details/', id]);
      }
    }
  }

  markAllAsRead() {
    this.updateNotification = true;
    this.authService.updateNotifications('read').subscribe(response => {
      this.updateNotification = false;
      this.getNotifications();
    })
  }

  markAllAsViewed() {
    if (this.notificationCount > 0) {
      this.authService.updateNotifications('viewed').subscribe(response => {
        this.notificationCount = 0;
      })
    }
  }
}
