import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'statusFormats'
})
export class StatusFormats implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value.indexOf('(') > -1) {
      value = value.substr(1).slice(0, -1);
      const name = value.split('-');
      return name[1];
    } else {
      const name = value.split('-');
      if (name[1]) {
        return name[1];
      }
      return name[0];
    }
  }
}
