import { NgModule } from '@angular/core';

import {
  SharedModule,
} from '@shared/index';

import {
  FooterComponent,
  HeaderComponent,
  SidebarComponent,
  TopNavComponent,
  BreadcrumbsComponent,
  NotfoundComponent,
  UnauthorizedComponent,
  LayoutComponent,
  SimpleLayoutComponent,
  NAV_DROPDOWN_DIRECTIVES,
  SIDEBAR_TOGGLE_DIRECTIVES
} from './layout/';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    TopNavComponent,
    BreadcrumbsComponent,
    NotfoundComponent,
    UnauthorizedComponent,
    SimpleLayoutComponent,
    LayoutComponent,
    NAV_DROPDOWN_DIRECTIVES,
    SIDEBAR_TOGGLE_DIRECTIVES
  ],
  exports: [

  ]
})
export class ThemeModule {}
