import { Routes } from '@angular/router';

import {
  AuthGuard,
  AccessGuard
} from '@shared/security';

import { moduleRoute } from '@salesonepro/index';

import {
  LayoutComponent,
  SimpleLayoutComponent,
  NotfoundComponent,
  UnauthorizedComponent
} from '@theme/layout/index';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
  path: '',
  component: LayoutComponent,
  canActivate: [ AuthGuard ],
  children: [
    {
      path: 'dashboard',
      loadChildren: moduleRoute.dashboard,
      canLoad: [ AuthGuard ]
    },
    {
      path: 'crm',
      canActivate: [AccessGuard],
      loadChildren: moduleRoute.crm,
      data: {
        acl: 'crm::crm'
      }
    },
    {
      path: 'hr',
      canActivate: [AccessGuard],
      loadChildren: moduleRoute.hr,
      data: {
        acl: 'hr::hr'
      }
    },
    {
      path: 'sales',
      canActivate: [AccessGuard],
      loadChildren: moduleRoute.sales,
      data: {
        acl: 'sales::sales'
      }
    },
    {
      path: 'settings',
      loadChildren: moduleRoute.settings,
      canActivate: [AccessGuard],
      data: {
        acl: 'settings::settings'
      }
    },
    {
      path: 'production',
      canActivate: [AccessGuard],
      loadChildren: moduleRoute.production,
      data: {
        acl: 'production::production'
      }
    },
    {
      path: 'reports',
      canActivate: [AccessGuard],
      loadChildren: moduleRoute.reports,
      data: {
        acl: 'reports::reports'
      }
    },
    {
      path: 'store',
      canActivate: [AccessGuard],
      loadChildren: moduleRoute.store,
      data: {
        acl: 'reports::reports'
      }
    },
  ]},
  {
    path: 'login',
    loadChildren: moduleRoute.login,
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
