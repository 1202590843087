import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Rx';

import { StorageService } from '@shared/services/storage.service';
import { ApiService } from '@shared/services/api.service';
import {
  AccessChecker
} from './access-checker.service';

@Injectable()
export class AccessGuard implements CanActivate {

  constructor(
    public router: Router,
    private accessChecker: AccessChecker,
    private storage: StorageService,
    private apiService: ApiService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const acl = route.data.acl;

    if (this.storage.retrieve('acl', 'session')) {
      if (!this.accessChecker.isGranted(acl)) {
        this.router.navigate(['unauthorized']);
        return false;
      } else {
        return true;
      }
    } else {
      console.log('loading acl..')
      return this.apiService.get('/users/acl')
      .switchMap(
        res => {
          this.storage.store('acl', res, 'session');
          console.log('loaded acl..')
          if (res && res.includes(acl)) {
            return Observable.of(true);
          } else {
            return Observable.of(false);
          }
        }
      );
    }
  }

}
