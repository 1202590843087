import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayRef,
  PositionStrategy,
  ScrollStrategy,
  ViewportRuler,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { DOWN_ARROW, ENTER, ESCAPE, TAB, UP_ARROW } from '@angular/cdk/keycodes';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  forwardRef,
  Host,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  ViewContainerRef,
  OnInit,
  HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { AutocompleteComponent } from './autocomplete';
import { AutocompleteOriginDirective } from './autocomplete-origin';
import { Subscription, defer, fromEvent, merge, of as observableOf, Subject, Observable } from 'rxjs';
import { filter, take, switchMap, delay, tap, map } from 'rxjs/operators';
import { AutocompleteOptionComponent, AutocompleteOptionSelectionChange, _getOptionScrollPosition } from './autocomplete-option';

/** Injection token that determines the scroll handling while the autocomplete panel is open. */
export const AUTOCOMPLETE_SCROLL_STRATEGY =
  new InjectionToken<() => ScrollStrategy>('autocomplete-scroll-strategy');

/** @docs-private */
export function AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition();
}

/** @docs-private */
export const AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: AUTOCOMPLETE_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY,
};

/**
 * Provider that allows the autocomplete to register as a ControlValueAccessor.
 * @docs-private
 */
export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutocompleteTriggerDirective),
  multi: true
};

/** The height of each autocomplete option. */
export const AUTOCOMPLETE_OPTION_HEIGHT = 48;

/** The total height of the autocomplete panel. */
export const AUTOCOMPLETE_PANEL_HEIGHT = 256;

@Directive({
  selector: `input[appAutocomplete], textarea[appAutocomplete]`,
  exportAs: 'AutocompleteTrigger',
  providers: [AUTOCOMPLETE_VALUE_ACCESSOR]
})

export class AutocompleteTriggerDirective implements ControlValueAccessor, OnDestroy {
  private _overlayRef: OverlayRef | null;
  private _portal: TemplatePortal;
  /** Strategy that is used to position the panel. */
  private _positionStrategy: FlexibleConnectedPositionStrategy;
  private _overlayAttached: boolean;
  /** Old value of the native input. Used to work around issues with the `input` event on IE. */
  private _previousValue: string | number | null;
  private _componentDestroyed = false;
  /**
   * Whether the autocomplete can open the next time it is focused. Used to prevent a focused,
   * closed autocomplete from being reopened if the user switches to another browser tab and then
   * comes back.
   */
  private _canOpenOnNextFocus = true;

  /** The subscription for closing actions (some are bound to document). */
  private _closingActionsSubscription: Subscription;
  /** Subscription to viewport size changes. */
  private _viewportSubscription = Subscription.EMPTY;

  /** Stream of keyboard events that can close the panel. */
  private readonly _closeKeyEventStream = new Subject<void>();


  /** Stream of autocomplete option selections. */
  readonly optionSelections: Observable<AutocompleteOptionSelectionChange> = defer(() => {
    if (this.appAutocomplete && this.appAutocomplete.options) {
    return merge(...this.appAutocomplete.options.map(option => option.onSelectionChange));
    }

    // If there are any subscribers before `ngAfterViewInit`, the `autocomplete` will be undefined.
    // Return a stream that we'll replace with the real one once everything is in place.
    return this._zone.onStable
        .asObservable()
        .pipe(take(1), switchMap(() => this.optionSelections));
  });

  /** The autocomplete panel to be attached to this trigger. */
  @Input() appAutocomplete: AutocompleteComponent;

  /**
   * Reference relative to which to position the autocomplete panel.
   * Defaults to the autocomplete trigger element.
   */
  @Input() appAutocompleteOrigin: AutocompleteOriginDirective;

  @HostListener('keydown', ['$event']) onkeydown($event) {
    this._handleKeydown($event)
  }

  @HostListener('focusin') onfocusin($event) {
    this._handleFocus()
  }

  @HostListener('input', ['$event']) oninput($event) {
    this._handleInput($event)
  }

  /** `View -> model callback called when value changes` */
  _onChange: (value: any) => void = () => {};

  /** `View -> model callback called when autocomplete has been touched` */
  _onTouched = () => {};

  /**
 * Event handler for when the window is blurred. Needs to be an
 * arrow function in order to preserve the context.
 */
  private _windowBlurHandler = () => {
    // If the user blurred the window while the autocomplete is focused, it means that it'll be
    // refocused when they come back. In this case we want to skip the first focus event, if the
    // pane was closed, in order to avoid reopening it unintentionally.
    this._canOpenOnNextFocus =
      document.activeElement !== this._element.nativeElement || this.panelOpen;
  }

  constructor(
    private _element: ElementRef<HTMLInputElement>,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _zone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(AUTOCOMPLETE_SCROLL_STRATEGY) private _scrollStrategy,
    @Optional() @Inject(DOCUMENT) private _document: any,
    // @breaking-change 7.0.0 Make `_viewportRuler` required.
    private _viewportRuler?: ViewportRuler) {
    if (typeof window !== 'undefined') {
      _zone.runOutsideAngular(() => {
        window.addEventListener('blur', this._windowBlurHandler);
      });
    }
  }

  ngOnDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('blur', this._windowBlurHandler);
    }

    this._viewportSubscription.unsubscribe();
    this._componentDestroyed = true;
    this._destroyPanel();
    this._closeKeyEventStream.complete();
  }

  /** Whether or not the autocomplete panel is open. */
  get panelOpen(): boolean {
    return this._overlayAttached && this.appAutocomplete.showPanel;
  }

  /** The currently active option, coerced to MatOption type. */
  get activeOption(): AutocompleteOptionComponent | null {
    if (this.appAutocomplete && this.appAutocomplete._keyManager) {
      return this.appAutocomplete._keyManager.activeItem;
    }

    return null;
  }

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any): void {
    Promise.resolve(null).then(() => this._setTriggerValue(value));
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => {}): void {
    this._onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: () => {}) {
    this._onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean) {
    this._element.nativeElement.disabled = isDisabled;
  }

  _handleFocus(): void {
    if (!this._canOpenOnNextFocus) {
      this._canOpenOnNextFocus = true;
    } else if (this._canOpen()) {
      this._previousValue = this._element.nativeElement.value;
      this._attachOverlay();
    }
  }

  _handleInput(event: KeyboardEvent): void {
    const target = event.target as HTMLInputElement;
    let value: number | string | null = target.value;

    // Based on `NumberValueAccessor` from forms.
    if (target.type === 'number') {
      value = value === '' ? null : parseFloat(value);
    }


    // If the input has a placeholder, IE will fire the `input` event on page load,
    // focus and blur, in addition to when the user actually changed the value. To
    // filter out all of the extra events, we save the value on focus and between
    // `input` events, and we check whether it changed.
    // See: https://connect.microsoft.com/IE/feedback/details/885747/
    if (this._previousValue !== value && document.activeElement === event.target) {
      this._previousValue = value;
      this._onChange(value);

      if (this._canOpen()) {
        this.openPanel();
      }
    }
  }

  _handleKeydown(event: KeyboardEvent): void {
    const keyCode = event.keyCode;
    // Prevent the default action on all escape key presses. This is here primarily to bring IE
    // in line with other browsers. By default, pressing escape on IE will cause it to revert
    // the input value to the one that it had on focus, however it won't dispatch any events
    // which means that the model value will be out of sync with the view.
    if (keyCode === ESCAPE) {
      event.preventDefault();
    }

    // Close when pressing ESCAPE or ALT + UP_ARROW, based on the a11y guidelines.
    // See: https://www.w3.org/TR/wai-aria-practices-1.1/#textbox-keyboard-interaction
    if (this.panelOpen && (keyCode === ESCAPE || (keyCode === UP_ARROW && event.altKey))) {
      this._resetActiveItem();
      this._closeKeyEventStream.next();
      // closePanel
      event.stopPropagation();
    } else if (keyCode === ENTER && this.panelOpen) {
      if (this.activeOption) {
        this.activeOption._selectViaInteraction();
      }
      this._resetActiveItem();
      event.preventDefault();
    } else if (this.appAutocomplete) {
      const prevActiveItem = this.appAutocomplete._keyManager.activeItem;
      const isArrowKey = keyCode === UP_ARROW || keyCode === DOWN_ARROW;

      if (this.panelOpen || keyCode === TAB) {
        this.appAutocomplete._keyManager.onKeydown(event);
      } else if (isArrowKey && this._canOpen()) {
        this.openPanel();
      }

      if (isArrowKey || this.appAutocomplete._keyManager.activeItem !== prevActiveItem) {
        this._scrollToOption();
      }
    }
  }

    /**
   * Given that we are not actually focusing active options, we must manually adjust scroll
   * to reveal options below the fold. First, we find the offset of the option from the top
   * of the panel. If that offset is below the fold, the new scrollTop will be the offset -
   * the panel height + the option height, so the active option will be just visible at the
   * bottom of the panel. If that offset is above the top of the visible panel, the new scrollTop
   * will become the offset. If that offset is visible within the panel already, the scrollTop is
   * not adjusted.
   */
  private _scrollToOption(): void {
    const index = this.appAutocomplete._keyManager.activeItemIndex || 0;
    const labelCount = 0;

    const newScrollPosition = _getOptionScrollPosition(
      index + labelCount,
      AUTOCOMPLETE_OPTION_HEIGHT,
      this.appAutocomplete._getScrollTop(),
      AUTOCOMPLETE_PANEL_HEIGHT
    );

    this.appAutocomplete._setScrollTop(newScrollPosition);
  }

  /**
   * Resets the active item to -1 so arrow events will activate the
   * correct options, or to 0 if the consumer opted into it.
   */
  private _resetActiveItem(): void {
    this.appAutocomplete._keyManager.setActiveItem(this.appAutocomplete.autoActiveFirstOption ? 0 : -1);
  }

  /** Opens the autocomplete suggestion panel. */
  openPanel(): void {
    this._attachOverlay();
  }

  /** Closes the autocomplete suggestion panel. */
  closePanel(): void {
    // this._resetLabel();

    if (!this._overlayAttached) {
      return;
    }

    if (this.panelOpen) {
      // Only emit if the panel was visible.
      this.appAutocomplete.closed.emit();
    }

    this.appAutocomplete._isOpen = this._overlayAttached = false;

    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
      // this._closingActionsSubscription.unsubscribe();
    }

    // Note that in some cases this can end up being called after the component is destroyed.
    // Add a check to ensure that we don't try to run change detection on a destroyed view.
    if (!this._componentDestroyed) {
      // We need to trigger change detection manually, because
      // `fromEvent` doesn't seem to do it at the proper time.
      // This ensures that the label is reset when the
      // user clicks outside.
      this._changeDetectorRef.detectChanges();
    }
  }

  /** Determines whether the panel can be opened. */
  private _canOpen(): boolean {
    const element = this._element.nativeElement;
    return !element.readOnly && !element.disabled;
  }

  private _attachOverlay(): void {
    if (!this.appAutocomplete) {
      throw Error('Attempting to open an undefined instance of `app-autocomplete`. ' +
      'Make sure that the id passed to the `Autocomplete` is correct and that ' +
      'you\'re attempting to open it after the ngAfterContentInit hook.');
    }

    if (!this._overlayRef) {
      this._portal = new TemplatePortal(this.appAutocomplete.template, this._viewContainerRef);
      this._overlayRef = this._overlay.create(this._getOverlayConfig());

      if (this._viewportRuler) {
        this._viewportSubscription = this._viewportRuler.change().subscribe(() => {
          if (this.panelOpen && this._overlayRef) {
            this._overlayRef.updateSize({ width: this._getPanelWidth() });
          }
        });
      }
    } else {
      // Update the panel width and direction, in case anything has changed.
      this._overlayRef.updateSize({ width: this._getPanelWidth() });
    }

    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this._overlayRef.attach(this._portal);
      this._closingActionsSubscription = this._subscribeToClosingActions();
    }

    const wasOpen = this.panelOpen;

    this.appAutocomplete._setVisibility();
    this.appAutocomplete._isOpen = this._overlayAttached = true;

    // We need to do an extra `panelOpen` check in here, because the
    // autocomplete won't be shown if there are no options.
    if (this.panelOpen && wasOpen !== this.panelOpen) {
      this.appAutocomplete.opened.emit();
    }

  }


/**
   * This method listens to a stream of panel closing actions and resets the
   * stream every time the option list changes.
   */
  private _subscribeToClosingActions(): Subscription {
    const firstStable = this._zone.onStable.asObservable().pipe(take(1));
    const optionChanges = this.appAutocomplete.options.changes.pipe(
      tap(() => this._positionStrategy.reapplyLastPosition()),
      // Defer emitting to the stream until the next tick, because changing
      // bindings in here will cause "changed after checked" errors.
      delay(0)
    );

    // When the zone is stable initially, and when the option list changes...
    return merge(firstStable, optionChanges)
      .pipe(
        // create a new stream of panelClosingActions, replacing any previous streams
        // that were created, and flatten it so our stream only emits closing events...
        switchMap(() => {
          // this._resetActiveItem();
          this.appAutocomplete._setVisibility();

          if (this.panelOpen) {
            this._overlayRef!.updatePosition();
          }

          return this.panelClosingActions;
        }),
        // when the first closing event occurs...
        take(1)
      )
      // set the value, close the panel, and complete.
      .subscribe(event => this._setValueAndClose(event));
  }

  /**
   * A stream of actions that should close the autocomplete panel, including
   * when an option is selected, on blur, and when TAB is pressed.
   */
  get panelClosingActions(): Observable<AutocompleteOptionSelectionChange|null> {
    return merge(
      this.optionSelections,
      this.appAutocomplete._keyManager.tabOut.pipe(filter(() => this._overlayAttached)),
      this._closeKeyEventStream,
      this._getOutsideClickStream(),
      this._overlayRef ?
          this._overlayRef.detachments().pipe(filter(() => this._overlayAttached)) :
          observableOf()
    ).pipe(
      // Normalize the output so we return a consistent type.
      map(event => event instanceof AutocompleteOptionSelectionChange ? event : null)
    );
  }

   /**
   * This method closes the panel, and if a value is specified, also sets the associated
   * control to that value. It will also mark the control as dirty if this interaction
   * stemmed from the user.
   */
  private _setValueAndClose(event: AutocompleteOptionSelectionChange | null): void {
    if (event && event.source) {
      this._clearPreviousSelectedOption(event.source);
      this._setTriggerValue(event.source.value);
      // this._onChange(event.source.value);
      this._element.nativeElement.focus();
      this.appAutocomplete._emitSelectEvent(event.source);
    }

    this.closePanel();
  }

  private _setTriggerValue(value: any): void {
    const toDisplay = this.appAutocomplete && this.appAutocomplete.displayWith ?
      this.appAutocomplete.displayWith(value) :
      value;

    // Simply falling back to an empty string if the display value is falsy does not work properly.
    // The display value can also be the number zero and shouldn't fall back to an empty string.
    const inputValue = toDisplay != null ? toDisplay : '';

    // If it's used within a `MatFormField`, we should set it through the property so it can go
    // through change detection.
    this._element.nativeElement.value = inputValue;
  }

    /**
   * Clear any previous selected option and emit a selection change event for this option
   */
  private _clearPreviousSelectedOption(skip: AutocompleteOptionComponent) {
    this.appAutocomplete.options.forEach(option => {
      if (option !== skip && option.selected) {
        option.deselect();
      }
    });
  }


  private _getPanelWidth(): number | string {
    return this.appAutocomplete.panelWidth || this._getHostWidth();
  }

  /** Returns the width of the input element, so the panel width can match it. */
  private _getHostWidth(): number {
    return this._getConnectedElement().nativeElement.getBoundingClientRect().width;
  }

  private _getOverlayConfig(): OverlayConfig {
    return {
      positionStrategy: this._getOverlayPosition(),
      scrollStrategy: this._scrollStrategy(),
      width: this._getPanelWidth(),
    };
  }

  private _getOverlayPosition(): PositionStrategy {
    this._positionStrategy = this._overlay.position()
      .flexibleConnectedTo(this._getConnectedElement())
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        }
      ]);

    return this._positionStrategy;
  }

  private _getConnectedElement(): ElementRef {
    if (this.appAutocompleteOrigin) {
      return this.appAutocompleteOrigin.elementRef;
    }
    return this._element;
  }

  /** Stream of clicks outside of the autocomplete panel. */
  private _getOutsideClickStream(): Observable<any> {
    if (!this._document) {
      return observableOf(null);
    }

    return merge(
      fromEvent<MouseEvent>(this._document, 'click'),
      fromEvent<TouchEvent>(this._document, 'touchend')
    )
      .pipe(filter(event => {
        const clickTarget = event.target as HTMLElement;

        return this._overlayAttached &&
          clickTarget !== this._element.nativeElement &&
          (!!this._overlayRef && !this._overlayRef.overlayElement.contains(clickTarget));
      }));
  }


  /** Destroys the autocomplete suggestion panel. */
  private _destroyPanel(): void {
    if (this._overlayRef) {
      this.closePanel();
      this._overlayRef.dispose();
      this._overlayRef = null;
    }
  }
}
