import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  NavigationExtras,
  CanLoad, Route
} from '@angular/router';
import { Observable } from 'rxjs/Rx';

import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    return this.authService.isAuthenticated.map(
        r => {
          const idToken = this.authService.getToken();
          const tokenExpired = idToken ? this.authService.isTokenExpired(idToken) : true;
          if (r && !tokenExpired) {
            // logged in so return true
            return true;
          }
          this.router.navigate(['/login']);
          return false;
        })
        .catch((error: any) => {
          this.router.navigateByUrl('/login');
          return Observable.of(false);
        });
  }

  canLoad(route: Route): boolean {
    const url = `/${route.path}`;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    // add logic for permission
    return Observable.of(true);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isAuthenticated.take(1).map(bool => !bool)) { return true; }

    // Store the attempted URL for redirecting
    this.authService.redirectUrl = url;

    // Create a dummy session id
    const sessionId = 123456789;

    // Set our navigation extras object
    // that contains our global query params and fragment
    const navigationExtras: NavigationExtras = {
      queryParams: { 'session_id': sessionId },
      fragment: 'anchor'
    };

    // Navigate to the login page with extras
    this.router.navigate(['/login'], navigationExtras);
    return false;
  }

}
