// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: true,
  apiUrl: 'https://fms-uat-1.fayastage.com/api/v1',
  authUrl: 'https://fms-uat-1.fayastage.com/oauth2',
  clientId: 'wAoTLw8R4s30AVJqpdkuMh4RFLjcuuenW8fIRDjC',
  socketUrl: 'ws://127.0.0.1:8000',
  clientSecret: 'EtPSgHxTlV7bawHqLJsjdKzxyP8swaRfHO4NTWtZI6xRmTRBwMTQdOzDiATAUNds6fNYIx6D9rC28UCqFcpZACXSOqloJfNSjfhfHb9bPUDTuKdjWdFYlKOFnF1oXpZB',
  tableRowLimit: '15',
  sanmarTrackingUrl: 'https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=',
  rocketShipServerUrl: 'https://printserver.local:8081'
};
