export const moduleRoute = {
  'dashboard': '@salesonepro/dashboard/dashboard.module#DashboardModule',
  'settings': '@salesonepro/settings/settings.module#SettingsModule',
  'customers': '@salesonepro/customer/customer.module#CustomerModule',
  'sales': '@salesonepro/sales/sales.module#SalesModule',
  'pre_production_work_orders': '@salesonepro/production/pre-production-work-orders/pre-production-work-orders.module#PreProductionWorkOrdersModule',
  'post_production_work_orders': '@salesonepro/production/post-production-work-orders/post-production-work-orders.module#PostProductionWorkOrdersModule',
  'workstation': '@salesonepro/sales/workstation/workstation.module#WorkStationModule',
  'supplier_orders': '@salesonepro/sales/supplier-orders/supplier-orders.module#SupplierOrdersModule',
  'orders': '@salesonepro/sales/orders/orders.module#OrdersModule',
  'crm': '@salesonepro/crm/crm.module#CrmModule',
  'leads': '@salesonepro/crm/leads/leads.module#LeadsModule',
  'opportunity': '@salesonepro/crm/opportunity/opportunity.module#OpportunityModule',
  'cases': '@salesonepro/crm/cases/cases.module#CasesModule',
  'campaign': '@salesonepro/crm/campaign/campaign.module#CampaignModule',
  'login': '@salesonepro/auth/auth.module#AuthModule',
  'production': '@salesonepro/production/production.module#ProductionModule',
  'hr': '@salesonepro/hr/hr.module#HrModule',
  'employees': '@salesonepro/hr/employees/employees.module#EmployeesModule',
  'reports': '@salesonepro/reports/reports.module#ReportsModule',
  'replacement_requests': '@salesonepro/sales/replacement-request/replacement-request.module#ReplacementRequestModule',
  'vendors': '@salesonepro/settings/sales-settings/vendors/vendors.module#VendorsModule',
  'suppliers': '@salesonepro/settings/sales-settings/supplier/supplier.module#SupplierModule',
  'decoration_method': '@salesonepro/settings/sales-settings/decoration-methods/decoration-method.module#DecorationMethodModule',
  'store': '@salesonepro/stores/stores.module#StoresModule',
  'store_orders': '@salesonepro/stores/store-orders/store-orders.module#StoreOrdersModule',
  'product_additional_pricing': '@salesonepro/settings/sales-settings/manage-product-additional-pricing/product-additional-pricing.module#ProductAdditionalPricingModule',
};
